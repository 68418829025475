<script setup>
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { computed, inject, ref, watch } from 'vue';

import Button from '@/Components/Button/Button.vue';
import ApproveHoursFormManualInputLog from './UpdateHoursFormManualInputLog.vue';
import ApproveHoursFormScanLog from './UpdateHoursFormScanLog.vue';
import ApproveHoursFormTimeForm from './UpdateHoursFormTimeForm.vue';
import ModalNoShow from './ModalNoShow.vue';

const dayjs = inject('dayjs');
const route = inject('route');

const props = defineProps({
    shift: Object,
    showing: Boolean,
    hideNextButton: Boolean,
});

const emits = defineEmits(['nextRowClicked', 'markedAsNoShow']);

const showNoShowModal = ref(false);

const openNoShowModal = () => {
    showNoShowModal.value = true;
};

const form = useForm({
    shift_id: props.shift.id,
    start: dayjs(props.shift.start).format('YYYY-MM-DD HH:mm'),
    end: dayjs(props.shift.end).format('YYYY-MM-DD HH:mm'),
    break: props.shift.break,
    rating: null,
    remark: '',
});

let originalStartTime = computed(() => dayjs(props.shift.start).format('YYYY-MM-DD HH:mm'));
let originalEndTime = computed(() => dayjs(props.shift.end).format('YYYY-MM-DD HH:mm'));
let originalBreak = computed(() => String(props.shift.break));

watch(
    () => props.shift,
    () => {
        form.shift_id = props.shift.id;
        form.start = dayjs(props.shift.start).format('YYYY-MM-DD HH:mm');
        form.end = dayjs(props.shift.end).format('YYYY-MM-DD HH:mm');
        form.break = props.shift.break;
        form.rating = null;
        form.remark = '';
    }
);

const timeIsDirty = computed(() => {
    return (
        originalStartTime.value != form.start || originalEndTime.value != form.end || originalBreak.value != form.break
    );
});

const startIsDirty = computed(() => originalStartTime.value != form.start);
const endIsDirty = computed(() => originalEndTime.value != form.end);
const breakIsDirty = computed(() => originalBreak.value != form.break);

const sendForm = (andNext = false, preserveState = false) => {
    const url = route('staff.shifts.update', props.shift.id);

    form.transform((data) => ({
        ...data,
        start: start.value.unix(),
        end: end.value.unix(),
    })).put(url, {
        preserveState: preserveState ? true : false,
        preserveScroll: true,
        onSuccess: () => (andNext ? emits('nextRowClicked') : null),
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

const start = computed(() => dayjs(form.start));
const end = computed(() => dayjs(form.end));

const isCompeted = props.shift.status === 'completed';

const remark = computed(() => props.shift.review?.review);
</script>

<template>
    <div class="grid">
        <div class="grid gap-8 mt-4 md:grid-cols-3 text-blue">
            <ApproveHoursFormTimeForm :shift="shift" :form="form" />
            <div>
                <div class="text-base">
                    <div class="flex gap-8">
                        <div v-if="shift.duration_by_flexer">
                            <p class="mb-3">{{ $t('Calculated') }}</p>
                            <span class="font-bold">{{ shift.duration_by_flexer }}</span>
                        </div>
                        <div v-if="shift.break_duration_by_flexer">
                            <p class="mb-3">{{ $t('Paused') }}</p>
                            <span class="font-bold">{{ shift.break_duration_by_flexer }}</span>
                        </div>
                    </div>
                    <ApproveHoursFormScanLog
                        v-if="typeof shift.scan_log != 'undefined' && shift.scan_log.length > 0"
                        :scanLog="shift.scan_log"
                    />
                    <ApproveHoursFormManualInputLog v-else :shift="shift" />
                </div>
            </div>
        </div>

        <div>
            <div class="flex justify-end mb-2">
                {{
                    $t('Please note that processed invoices or export files to Easyflex will need to be fixed manually')
                }}
            </div>
            <div class="flex justify-end gap-4">
                <Button v-if="shift.status !== 'no_show'" @click="openNoShowModal()" danger class="inline-flex w-fit">
                    <div class="gap-2 font-bold flex-center">
                        <span>{{ $t('Absent') }}</span>
                    </div>
                </Button>

                <Button
                    @click="sendForm"
                    :warning="timeIsDirty"
                    :disabled="!timeIsDirty"
                    orange
                    class="inline-flex w-fit"
                >
                    <div class="gap-2 font-bold flex-center">
                        <span>{{ $t('Amend') }}</span>
                    </div>
                </Button>
            </div>
        </div>
    </div>

    <ModalNoShow v-model:showing="showNoShowModal" :shift="shift" @marked-as-no-show="() => emits('markedAsNoShow')" />
</template>
